.big-error-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #0bb3ba;
  display: flex;
  align-items: center;
  justify-content: center;
}

.big-error-indicator {
  font-size: 210px;
  color: #fff;
}