@keyframes ldio-81fss5q5czi {
  0% {
    transform: translate(1px, 1px)
  }
  33.33% {
    transform: translate(51px, 1px)
  }
  66.66% {
    transform: translate(21px, 51px)
  }
  100% {
    transform: translate(1px, 1px)
  }
}

.ldio-81fss5q5czi>div {
  transform: scale(0.8);
  transform-origin: 50px 50px;
}

.ldio-81fss5q5czi>div>div {
  animation: ldio-81fss5q5czi 1s linear infinite;
  position: absolute
}

.ldio-81fss5q5czi>div>div div:nth-child(1) {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 6px solid #0bb3ba;
  background: #9fd8e7
}

.ldio-81fss5q5czi>div>div div:nth-child(2) {
  width: 8.5px;
  height: 25.5px;
  transform: rotate(-45deg);
  background: #0bb3ba;
  border-radius: 0 0 4px 4px;
  position: absolute;
  top: 34px;
  left: 42.5px
}

.loadingio-spinner-magnify-nn1fehrbzvm {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}

.spinner {
  text-align: center;
}
.ldio-81fss5q5czi {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-81fss5q5czi div {
  box-sizing: content-box;
}